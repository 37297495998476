import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Disqus from 'disqus-react'

import Layout from '../components/Layout'

// eslint-disable-next-line
import ShareImages from '../fragments/ShareImagesFragment'

import {
  SideBar,
  ScrollToTopOfPage,
  ContentfulSiteProductRoundUp,
  NewsletterSignUpForm,
} from 'blog-components'

const messages = {
  newslettersignupform: {
    title: 'Newsletter Signup',
    lead: 'Like what you just read? Signup to our newsletter for more!',
    submit_label: 'Sign Up',
    name_label: 'Name',
    name_placeholder: 'Your name...',
    email_label: 'Email',
    email_placeholder: 'Your email address...',
    success: 'Thanks for signing up!',
  },
}

class ProductRoundUpTemplate extends ScrollToTopOfPage {
  render() {
    const site = get(this, 'props.data.site')

    const relatedPosts = get(this, 'props.data.post.relatedPosts')

    const title = get(this, 'props.data.post.title')
    const authors = get(this, 'props.data.post.authors')
    const description = get(
      this,
      'props.data.post.description.childMarkdownRemark.rawMarkdownBody'
    )
    const slug = get(this, 'props.data.post.slug')
    const publishDate = get(this, 'props.data.post.publishDate')
    const createdAt = get(this, 'props.data.post.createdAt')
    const updatedAt = get(this, 'props.data.post.updatedAt')
    const shareImage = get(this, 'props.data.post.shareImage.fixed_1200x630.src')

    const slot1 = get(this, 'props.data.post.slot1.childMarkdownRemark.htmlAst')
    const slot2 = get(this, 'props.data.post.slot2.childMarkdownRemark.htmlAst')
    const slot3 = get(this, 'props.data.post.slot3.childMarkdownRemark.htmlAst')
    const slot4 = get(this, 'props.data.post.slot4.childMarkdownRemark.htmlAst')
    const slot5 = get(this, 'props.data.post.slot5.childMarkdownRemark.htmlAst')
    const categories = get(this, 'props.data.post.categories')
    const products = get(this, 'props.data.post.products')
    const topPickBadgeFluid = get(
      this,
      'props.data.topPickBadge.childImageSharp.fluid'
    )
    const affiliateId = get(this, 'props.data.post.affiliateId')
    const attributesToDisplay = get(this, 'props.data.post.attributesToDisplay')

    const disqusConfig = {
      url: site.siteMetadata.siteUrl + slug,
      identifier: slug,
      title: title,
    }

    return (
      <Layout>
        <div className="container fadein-3 blog-post" data-emergence="visible">
          <div className="row">
            <div className="articles col-lg-10">
              <ContentfulSiteProductRoundUp
                slug={slug}
                title={title}
                authors={authors}
                publishDate={publishDate}
                createdAt={createdAt}
                updatedAt={updatedAt}
                shareImage={shareImage}
                description={description}
                categories={categories}
                slot1={slot1}
                slot2={slot2}
                slot3={slot3}
                slot4={slot4}
                slot5={slot5}
                affiliateId={affiliateId}
                attributesToDisplay={attributesToDisplay}
                site={site}
                products={products}
                topPickBadgeFluid={topPickBadgeFluid}
              />
            </div>
            <div className="sidebar col-lg-2">
              <SideBar relatedPosts={relatedPosts} />
            </div>
          </div>

          <div className="row">
            <div className="offset-lg-1 col-lg-10">
              <NewsletterSignUpForm
                title={messages.newslettersignupform.title}
                lead={messages.newslettersignupform.lead}
                submit_label={messages.newslettersignupform.submit_label}
                name_label={messages.newslettersignupform.name_label}
                name_placeholder={
                  messages.newslettersignupform.name_placeholder
                }
                email_label={messages.newslettersignupform.email_label}
                email_placeholder={
                  messages.newslettersignupform.email_placeholder
                }
                success={messages.newslettersignupform.success}
              />
            </div>
          </div>

          <div className="row">
            <div className="offset-lg-1 col-lg-10">
              <Disqus.DiscussionEmbed
                shortname={site.siteMetadata.disqusShortname}
                config={disqusConfig}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ProductRoundUpTemplate

export const pageQuery = graphql`
  query ProductRoundupByPath($path: String!) {
    site {
      ...SiteInformation
    }
    topPickBadge: file(relativePath: { eq: "top-pick-badge.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    post: contentfulProductRoundUp(slug: { eq: $path }) {
      title
      slug
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      slot1 {
        childMarkdownRemark {
          htmlAst
        }
      }
      slot2 {
        childMarkdownRemark {
          htmlAst
        }
      }
      slot3 {
        childMarkdownRemark {
          htmlAst
        }
      }
      slot4 {
        childMarkdownRemark {
          htmlAst
        }
      }
      slot5 {
        childMarkdownRemark {
          htmlAst
        }
      }
      relatedPosts {
        slug
        title
        visible
      }
      publishDate(formatString: "DD/MM/YYYY")
      createdAt(formatString: "DD/MM/YYYY")
      updatedAt(formatString: "DD/MM/YYYY")
      shareImage {
        ...ShareImages
      }
      authors {
        slug
        name
      }
      categories {
        title
        slug
      }
      affiliateId
      attributesToDisplay {
        label
      }
      products {
        id
        shortTitle
        longTitle
        sellingPlatform
        buyingUrl
        brand
        images {
          fixedHeight150: fixed(height: 150) {
            ...GatsbyContentfulFixed_withWebp
          }
          fixedHeight200: fixed(height: 200) {
            ...GatsbyContentfulFixed_withWebp
          }
          fixedHeight300: fixed(height: 300, quality: 90) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        introduction {
          childMarkdownRemark {
            htmlAst
          }
        }
        fullCopy {
          childMarkdownRemark {
            htmlAst
          }
        }
        attribute1 {
          label
        }
        attribute1Value
        attribute2 {
          label
        }
        attribute2Value
        attribute3 {
          label
        }
        attribute3Value
        attribute4 {
          label
        }
        attribute4Value
        attribute5 {
          label
        }
        attribute5Value
        rating1 {
          label
        }
        rating1Value
        rating2 {
          label
        }
        rating2Value
        rating3 {
          label
        }
        rating3Value
        rating4 {
          label
        }
        rating4Value
        rating5 {
          label
        }
        rating5Value
        award {
          label
        }
        pros
        cons
      }
    }
  }
`
